import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Footer from "./footer";
import Gallery from "./gallery";
import Header from "./header";
import MainPage from "./main";
import Nav from "./nav";
import AboutUs from "./about-us";

function Layout() {
  return (
    <>
      <Nav />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Content>
                <div style={{ maxWidth: "2500px" }}>
                  <MainPage />
                  <Footer />
                </div>
              </Content>
            </>
          }
        />

        <Route
          path="/gallery"
          element={
            <div style={{ maxWidth: "2500px" }}>
              <Gallery />
              <Footer />
            </div>
          }
        />

        <Route
          path="/about-us"
          element={
            <div style={{ maxWidth: "2500px" }}>
              <AboutUs />
              <Footer />
            </div>
          }
        />
      </Routes>
    </>
  );
}
export default Layout;

const Content = styled.div`
  position: relative;
  top: 100vh;
  background: white;
`;
