import { useState, useEffect } from "react";
import Contact from "./main/contact";
import { Schedule } from "./main/schedule";
import ScheduleMobile from "./main/scheduleMobile";
import { Services } from "./main/services";

const MainPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Contact />
      <Services />
      {isMobile ? <ScheduleMobile /> : <Schedule />}
    </>
  );
};

export default MainPage;
