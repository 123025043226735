import styled from "styled-components";

const FlexContainer = styled.div<{
  mainPadding?: boolean;
  centeredAlign?: boolean;
  $centeredJustify?: boolean;
  column?: boolean;
}>`
  display: flex;
  ${({ centeredAlign }) => centeredAlign && `align-items: center;`};
  ${({ $centeredJustify }) => $centeredJustify && `justify-content: center;`};
  ${({ column }) => column && `flex-direction: column;`};
  ${({ mainPadding }) => mainPadding && `padding: 30px 40px`}
`;

export { FlexContainer };
